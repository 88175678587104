<template>
  <div class="password-reset-container">
    <form @submit.prevent="resetPassword">
      <label for="newPassword">New Password:</label>
      <input type="password" id="newPassword" v-model="newPassword" required>
      <OkModal v-on:dismissed="redirectHome"
          title="Success"
          message="Password Reset"
          :showvar.sync="showWarning"
      />
      <button type="submit">Reset Password</button>
    </form>
  </div>
</template>

<script>
import firebase from "firebase";
import OkModal from "@/views/widgets/modals/OkModal.vue";

export default {
  name:'ResetPage',
  components: { OkModal },
  data() {
    return {
      newPassword: '',
      showWarning: false
    };
  },
  methods: {
    redirectHome() {
      this.$router.push('/');
    },
    async resetPassword() {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const oobCode = urlParams.get('oobCode');
        if (!oobCode) {
          throw new Error('No reset code found in URL.');
        }

        await firebase.auth().confirmPasswordReset(oobCode, this.newPassword);
        this.showWarning = true;
        // Redirect or update UI
      } catch (error) {
        console.error("Error resetting password: ", error);
        // Handle errors here, such as displaying a message to the user
      }
    },
  },
};
</script>

<style scoped>
/* Add your CSS styling here */
.password-reset-container {
  /* Style your container */
}

input, button {
  /* Style your input and button */
}
</style>
