
















import {vxm} from "@/store";
import {Component, Vue, Prop, PropSync} from 'vue-property-decorator';


@Component
export default class OkModal extends Vue {
    @Prop(String) readonly title: string | undefined;
    @Prop(String) readonly message: string | undefined;
    @PropSync('showvar', { type: Boolean }) syncedShow!: boolean;
    @PropSync('dismissed', { type: Boolean }) syncedDismiss!: boolean;
    private ui = vxm.ui;

    private dismiss(){
       this.syncedShow=false;
       this.syncedDismiss=true;
       this.$emit('dismissed');
    }

}
